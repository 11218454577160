/****************************** components ******************************/

@import "./components/mixin";
@import "./components/default";
@import "./components/normalize";
@import "./components/reset";
@import "./components/grid";
@import "./components/case";

/****************************** style ******************************/

body,
button,
input,
select,
textarea {
    font: 12px/1.4 "\5FAE\8F6F\96C5\9ED1", "arial";
    color: #5a5a5a;
    font-size: 12px;
}

html {
    min-width: 1200px;
}

ol li,
ul li {
    list-style-type: none;
    vertical-align: middle;
}

img {
    border: none;
    vertical-align: middle;
}

input {
    vertical-align: middle;
    font: 12px/1.4 "\5FAE\8F6F\96C5\9ED1", "arial";
    outline: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

a {
    text-decoration: none;
}

.w1200 {
    width: 1200px;
    margin: 0 auto;
    z-index: 999;
    position: relative;
}

.clear {
    clear: both;
}

.fl {
    float: left;
}

.fr {
    float: right;
}

.box_top {
    width: 100%;
    margin: 0 auto;
}

.box_top_top {
    width: 100%;
    height: 45px;
    background-color: rgb(41, 49, 51);
}

.top_top {
    height: 45px;
}

.top_top_left p {
    color: #fff;
    font-size: 14px;
    line-height: 45px;
}

.top_top_right p {
    color: #fff;
    font-size: 14px;
    line-height: 45px;
    span {
        float: left;
        img {
            display: inline;
            width: 20px;
            height: 20px;
            line-height: 45px;
            margin-right: 10px;
        }
    }
}

.box_top_bot {
    width: 100%;
    height: 90px;
    background-color: rgb(51, 61, 64);
}

.top_bot {
    height: 90px;
}

.top_bot_left p {
    line-height: 90px;
    font-size: 36px;
    color: #fff;
    img {
        display: inline;
        width: 65px;
        height: 65px;
        line-height: 90px;
        margin-right: 20px;
    }
}

.top_bot_right p {
    line-height: 90px;
    img {
        display: inline;
        width: 45px;
        height: 45px;
        line-height: 90px;
        margin-right: 15px;
    }
}

.box_nav {
    width: 100%;
    height: 67px;
    margin: 0 auto;
    background-color: rgb(247, 247, 247);
    z-index: 99999;
}

.nav {
    height: 67px;
    z-index: 99;
    ul {
        padding-left: 20px;
        z-index: 99;
        li {
            float: left;
            z-index: 99;
            a {
                display: block;
                width: 123px;
                height: 90px;
                color: #000;
                font-size: 15px;
                text-align: center;
                line-height: 67px;
                z-index: 99;
                &:hover {
                    color: #fff;
                    background: url(../img/li_hover.png) no-repeat top center;
                    z-index: 99;
                }
            }
            .navhover {
                color: #fff;
                background: url(../img/li_hover.png) no-repeat top center;
                z-index: 99;
            }
        }
    }
}

.search {
    width: 290px;
    height: 30px;
    line-height: 30px;
    border: #f7b71d 1px solid;
    margin-top: 20px;
    input[type="text"] {
        height: 28px;
        line-height: 28px;
        text-indent: 2em;
        border: none;
        font-size: 14px;
        background-color: rgb(247, 247, 247);
        margin-bottom: 5px;
        color: #f7b71d;
        @include placeholder;
    }
    .btn {
        display: block;
        background: #f7b71d;
        border: #fff 0px solid;
        float: right;
        width: 50px;
        line-height: 28px;
        cursor: pointer;
        color: #fff;
        font-size: 14px;
        font-weight: normal;
        text-align: center;
    }
    select {
        color: rgb(247, 183, 29);
        border: none;
        height: 27px;
        line-height: 27px;
        background-color: rgb(247, 247, 247);
        font-size: 14px;
        text-indent: 1px;
    }
}

.box_banner {
    width: 100%;
    height: 500px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    .banner {
        height: 540px;
        position: relative;
        .banner_ad_cover {
            position: absolute;
            right: 250px;
            top: 120px;
            z-index: 105;
            width: 490px;
            height: 290px;
            background-color: #fff;
            border-radius: 8px;
            opacity: 0.9;
        }
    }
    .banner_ad {
        position: absolute;
        top: 100px;
        right: 0px;
        z-index: 105;
        width: 490px;
        height: 290px;
        background-color: #fff;
        border-radius: 8px;
        opacity: 0.9;
        text-indent: 35px;
        h3 {
            width: 50px;
            border-bottom: 4px solid rgb(247, 183, 29);
            margin: 40px 0 25px 35px;
        }
        h2 {
            font-size: 43px;
            letter-spacing: 1px;
            font-weight: bold;
            .p1 {
                color: #000;
            }
            .p2 {
                color: rgb(247, 183, 29);
            }
        }
        .p3 {
            font-size: 17px;
            line-height: 160%;
            letter-spacing: 1px;
            color: #5a5a5a;
            margin-top: 10px;
        }
        .p4 {
            font-size: 17px;
            line-height: 160%;
            letter-spacing: 1px;
            color: #5a5a5a;
            margin-bottom: 20px;
        }
        a {
            display: block;
            width: 150px;
            height: 50px;
            font-size: 14px;
            color: #fff;
            background: rgb(247, 183, 29);
            line-height: 50px;
            text-align: center;
            margin-left: 35px;
            text-indent: 0px;
            border-radius: 3px;
            transition: background-color 0.8s ease 0s;
            &:hover {
                background-color: #e19f00;
            }
        }
    }
}

.box_service {
    width: 100%;
    margin: 0 auto;
}

.service {
    h2 {
        color: #000;
        font-size: 30px;
        line-height: 42px;
        text-align: center;
        font-weight: bold;
        margin-top: 40px;
    }
    h3 {
        display: block;
        width: 220px;
        height: 26px;
        margin-top: -15px;
        background: #fff;
        font-size: 14px;
        line-height: 26px;
        color: #5a5a5a;
        margin: -15px auto;
        text-align: center;
    }
    div {
        width: 460px;
        height: 42px;
        margin: 15px auto 30px;
        border-top: 1px solid rgb(102, 102, 102);
    }
    ul {
        li {
            float: left;
            width: 220px;
            margin-right: 106px;
            img {
                display: block;
                width: 80px;
                height: 80px;
                margin: 0 auto 15px;
                transition: all 3s;
            }
            .p1 {
                text-align: center;
                font-size: 16px;
                line-height: 140%;
                color: #000;
                font-weight: bold;
                margin-bottom: 10px;
                display: none;
                position: relative;
                left: -500px;
            }
            .p2 {
                text-align: center;
                font-size: 12px;
                line-height: 160%;
                color: #5a5a5a;
                margin-bottom: 50px;
                display: none;
                position: relative;
                left: -500px;
            }
        }
    }
}

.box_aboutus {
    width: 100%;
    height: 590px;
    margin: 0 auto;
    background-color: rgb(247, 183, 29);
    h2 {
        color: #fff;
        font-size: 30px;
        line-height: 42px;
        text-align: center;
        font-weight: bold;
        padding-top: 40px;
    }
    h3 {
        display: block;
        width: 180px;
        height: 26px;
        margin-top: -15px;
        background-color: rgb(247, 183, 29);
        font-size: 14px;
        line-height: 26px;
        color: #fff;
        margin: -15px auto;
        text-align: center;
    }
}

.aboutus {
    height: 590px;
    background: url(../img/aboutusw1200_bg.png) no-repeat top center;
}

.box_aboutus {
    .line {
        width: 460px;
        height: 42px;
        margin: 15px auto 50px;
        border-top: 1px solid #fff;
    }
}

.aboutus_left {
    width: 600px;
    margin-right: 50px;
    p {
        font-size: 15px;
        line-height: 175%;
        color: #fff;
        text-indent: 2em;
        margin-bottom: 30px;
        display: none;
        position: relative;
        left: -500px;
    }
}

.aboutus_right {
    width: 550px;
    position: relative;
    img {
        width: 550px;
        height: auto;
        display: none;
        position: relative;
        left: 500px;
    }
    a {
        display: block;
        width: 150px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        background: #000;
        color: #fff;
        position: absolute;
        left: -70px;
        bottom: -10px;
        font-size: 45px;
        -webkit-border-radius: 3px;
        border-radius: 3px;
        transition: background-color 0.8s ease 0s, color 0.8s ease 0s;
        transition: all 2s;
        &:hover {
            background: #fff;
            color: rgb(247, 183, 29);
        }
    }
}

.box_prodisplay {
    width: 100%;
    margin: 0 auto;
}

.prodisplay {
    position: relative;
    h2 {
        color: #000;
        font-size: 30px;
        line-height: 42px;
        text-align: center;
        font-weight: bold;
        margin-top: 40px;
    }
    h3 {
        display: block;
        width: 160px;
        height: 26px;
        background: #fff;
        font-size: 14px;
        line-height: 26px;
        color: #5a5a5a;
        margin: -40px auto;
        text-align: center;
    }
    .line {
        width: 460px;
        height: 42px;
        margin: 15px auto 30px;
        border-top: 1px solid rgb(102, 102, 102);
        padding: 28px 0 50px;
    }
    ul {
        li {
            float: left;
            width: 280px;
            margin-right: 22px;
            margin-bottom: 22px;
            padding: 15px;
            border: 1px solid rgb(238, 238, 238);
            &:hover {
                border-color: rgb(247, 183, 29);
            }
            .img {
                display: block;
                width: 220px;
                height: 220px;
            }
            .p1 {
                margin-bottom: 8px;
                a {
                    display: block;
                    font-size: 16px;
                    color: #f7b71d;
                    display: block;
                    text-align: center;
                    line-height: 140%;
                }
            }
            .p2 {
                font-size: 12px;
                color: #acacac;
                line-height: 140%;
                text-align: center;
                span {
                    color: #ff3c00;
                }
            }
        }
    }
    .btn {
        height: 50px;
        margin-bottom: 30px;
        .more {
            display: block;
            width: 150px;
            height: 50px;
            font-size: 14px;
            color: #fff;
            background: rgb(247, 183, 29);
            line-height: 50px;
            text-align: center;
            margin: 0 auto;
            text-indent: 0px;
            -webkit-border-radius: 3px;
            border-radius: 3px;
            margin-bottom: 30px;
            transition: background-color 0.8s ease 0s;
            transition: all 2s;
            &:hover {
                background-color: #e19f00;
            }
        }
    }
}

.box_cases {
    width: 100%;
    margin: 0 auto;
    background-color: rgb(249, 249, 249);
    padding-bottom: 50px;
}

.cases {
    h2 {
        color: #000;
        font-size: 30px;
        line-height: 42px;
        text-align: center;
        font-weight: bold;
        padding-top: 40px;
    }
    h3 {
        display: block;
        width: 153px;
        height: 26px;
        margin-top: -15px;
        background-color: rgb(249, 249, 249);
        font-size: 14px;
        line-height: 26px;
        color: #5a5a5a;
        margin: -15px auto;
        text-align: center;
    }
    .line {
        width: 460px;
        height: 42px;
        margin: 15px auto 30px;
        border-top: 1px solid rgb(102, 102, 102);
    }
}

.box_info {
    width: 100%;
    margin: 0 auto;
}

.info {
    h2 {
        color: #000;
        font-size: 30px;
        line-height: 42px;
        text-align: center;
        font-weight: bold;
        padding-top: 40px;
    }
    h3 {
        display: block;
        width: 150px;
        height: 26px;
        margin-top: -15px;
        background: #fff;
        font-size: 14px;
        line-height: 26px;
        color: #5a5a5a;
        margin: -15px auto;
        text-align: center;
    }
    .line {
        width: 460px;
        height: 42px;
        margin: 15px auto 30px;
        border-top: 1px solid rgb(102, 102, 102);
    }
    .btn {
        height: 50px;
        margin: 0 auto 30px;
        .more {
            display: block;
            width: 150px;
            height: 50px;
            font-size: 14px;
            color: #fff;
            background: rgb(247, 183, 29);
            line-height: 50px;
            text-align: center;
            margin: 0 auto 30px;
            text-indent: 0px;
            -webkit-border-radius: 3px;
            border-radius: 3px;
            transition: background-color 0.8s ease 0s;
            transition: all 2s;
            &:hover {
                background-color: #e19f00;
            }
        }
    }
}

.info_left {
    width: 230px;
    ul {
        li {
            margin-bottom: 5px;
            a {
                display: block;
                width: 230px;
                height: 120px;
                line-height: 120px;
                text-align: center;
                color: #000;
                font-size: 20px;
                background-color: #f9f9f9;
                letter-spacing: 2px;
                &:hover {
                    background: #f7b71d;
                    color: #fff;
                }
            }
        }
    }
}

.info_right {
    width: 880px;
    padding-bottom: 40px;
    ul {
        li {
            width: 880px;
            border-bottom: 1px dashed #dbd4cd;
            padding-top: 15px;
            .div {
                width: 70px;
                height: 65px;
                margin-left: 30px;
                float: left;
                text-align: center;
                background: #f7b71d;
                margin-right: 40px;
                padding: 0;
                .p1 {
                    display: block;
                    text-align: center;
                    color: #fff;
                    font-size: 24px;
                    font-weight: bold;
                    margin-top: 5px;
                }
                .p2 {
                    display: block;
                    text-align: center;
                    color: #fff;
                    font-size: 13px;
                    margin-top: 5px;
                }
            }
            .p3 {
                a {
                    display: inline-block;
                    font-size: 22px;
                    color: #000000;
                    font-weight: normal;
                    text-decoration: none;
                    line-height: 140%;
                    &:hover {
                        color: #f7b71d;
                    }
                }
            }
            .p4 {
                font-size: 12px;
                padding-top: 2px;
                color: #dbd4cd;
                font-weight: normal;
                text-decoration: none;
                line-height: 140%;
                padding-bottom: 10px;
            }
        }
    }
}

.box_partners {
    width: 100%;
    margin: 0 auto;
    background-color: rgb(249, 249, 249);
}

.partners {
    h2 {
        color: #000;
        font-size: 30px;
        line-height: 42px;
        text-align: center;
        font-weight: bold;
        padding-top: 40px;
    }
    h3 {
        display: block;
        width: 150px;
        height: 26px;
        margin-top: -15px;
        background-color: rgb(249, 249, 249);
        font-size: 14px;
        line-height: 26px;
        color: #5a5a5a;
        margin: -15px auto;
        text-align: center;
    }
    .line {
        width: 460px;
        height: 42px;
        margin: 15px auto 30px;
        border-top: 1px solid rgb(102, 102, 102);
    }
}

.box_footer {
    width: 100%;
    height: 240px;
    margin: 0 auto;
    background-color: rgb(41, 49, 51);
}

.footer {
    height: 240px;
    position: relative;
}

.footer_left {
    width: 330px;
    .p1 {
        line-height: 40px;
        color: #fff;
        font-size: 25px;
        margin-bottom: 15px;
        margin-top: 25px;
        img {
            width: 40px;
            height: 40px;
            margin-right: 15px;
        }
    }
    .p2 {
        color: rgb(217, 217, 217);
        font-size: 13px;
        line-height: 140%;
        margin-bottom: 15px;
    }
    .p3 {
        img {
            width: 30px;
            height: 30px;
        }
    }
}

.border1 {
    width: 1px;
    height: 150px;
    border-right: 1px solid rgb(102, 102, 102);
    position: absolute;
    left: 329px;
    top: 50px;
}

.border2 {
    width: 1px;
    height: 150px;
    border-right: 1px solid rgb(102, 102, 102);
    position: absolute;
    left: 929px;
    top: 50px;
}

.footer_mid {
    margin-left: 50px;
    width: 500px;
    h2 {
        margin-bottom: 15px;
        margin-top: 25px;
        color: #fff;
        font-size: 18px;
        line-height: 35px;
    }
    h3 {
        width: 35px;
        border-bottom: 2px solid rgb(247, 183, 29);
    }
    ul {
        li {
            width: 500px;
            border-bottom: 1px dashed #c6c6c6;
            line-height: 30px;
        }
    }
    .cate {
        display: inline;
        font-size: 12px;
        color: #f7b71d;
        margin-right: 8px;
        &:hover {
            color: #0064d4;
        }
    }
    .title {
        display: inline;
        font-size: 12px;
        color: #d9d9d9;
        &:hover {
            color: #f7b71d;
        }
    }
    span {
        font-size: 11px;
        color: #acacac;
    }
}

.footer_right {
    margin-left: 100px;
    h2 {
        margin-bottom: 15px;
        margin-top: 25px;
        color: #fff;
        font-size: 18px;
        line-height: 35px;
    }
    h3 {
        width: 35px;
        border-bottom: 2px solid rgb(247, 183, 29);
        margin-bottom: 20px;
    }
    img {
        width: 110px;
        height: 110px;
    }
}

.box_position {
    width: 100%;
    height: 70px;
    margin: 0 auto;
    background-color: rgb(247, 183, 29);
}

.position_left {
    width: 600px;
    height: 70px;
    line-height: 70px;
    .p1 {
        font-size: 25px;
        color: rgb(255, 255, 255);
    }
    .p2 {
        color: rgb(255, 255, 255);
        font-size: 15px;
    }
}

.position_right {
    width: 600px;
    height: 70px;
    line-height: 70px;
    .p1 {
        a {
            display: inline;
            font-size: 15px;
            color: #fff;
        }
    }
    .p2 {
        a {
            display: inline;
            font-size: 15px;
            color: #fff;
        }
    }
    .p3 {
        font-size: 15px;
        color: #fff;
        margin: 0 10px;
    }
    a {
        &:hover {
            text-decoration: underline;
        }
    }
}

.gywm_content {
    margin: 20px auto;
    p {
        font-size: 14px;
        line-height: 26px;
        color: #000;
        padding: 10px;
        text-indent: 2em;
        position: relative;
        left: -1000px;
        opacity: 0;
    }
}

.box_gywm_ad {
    width: 100%;
    height: 390px;
    margin: 0 auto;
    background: url(../img/gywmad_bg.jpg) no-repeat left top;
    background-color: rgb(247, 183, 29);
}

.gywm_ad_c {
    width: 560px;
    ul {
        margin-left: 50px;
        margin-top: 60px;
        li {
            float: left;
            width: 220px;
            margin-right: 60px;
            margin-bottom: 60px;
            position: relative;
            left: 1000px;
            opacity: 0;
            img {
                width: 50px;
                height: 50px;
                margin-left: 10px;
                margin-bottom: 10px;
            }
            .p1 {
                font-size: 18px;
                color: rgb(255, 255, 255);
                font-weight: bold;
                line-height: 50px;
            }
            .p2 {
                line-height: 160%;
                color: rgb(238, 238, 238);
                font-size: 13px;
            }
        }
    }
}

.box_cpzx {
    width: 100%;
    margin: 0 auto;
}

.cpzx {
    .catlist {
        width: 720px;
        height: 40px;
        margin: 50px auto;
    }
    .ul1 {
        li {
            float: left;
            a {
                display: block;
                width: 120px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                color: #000;
                font-size: 14px;
                background-color: #f9f9f9;
                &:hover {
                    color: #fff;
                    background-color: rgb(247, 183, 29);
                }
            }
        }
    }
    .ul2 {
        li {
            float: left;
            width: 280px;
            margin-right: 22px;
            margin-bottom: 22px;
            padding: 15px;
            border: 1px solid rgb(238, 238, 238);
            &:hover {
                border-color: rgb(247, 183, 29);
            }
            .img {
                display: block;
                width: 250px;
                height: 200px;
            }
            .p1 {
                margin-bottom: 8px;
                a {
                    display: block;
                    font-size: 16px;
                    color: #f7b71d;
                    display: block;
                    text-align: center;
                    line-height: 140%;
                }
            }
            .p2 {
                font-size: 12px;
                color: #acacac;
                line-height: 140%;
                text-align: center;
                span {
                    color: #ff3c00;
                }
            }
        }
    }
    .img {
        width: 300px;
        height: 250px;
    }
    h4 {
        float: right;
        width: 810px;
        font-size: 20px;
        line-height: 33px;
    }
}

.box_rdzx {
    width: 100%;
    margin: 0 auto;
}

.rdzx {
    .cate {
        width: 360px;
        height: 40px;
        margin: 50px auto;
    }
    .ul1 {
        li {
            float: left;
            a {
                display: block;
                width: 120px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                color: #000;
                font-size: 14px;
                background-color: #f9f9f9;
                &:hover {
                    color: #fff;
                    background-color: rgb(247, 183, 29);
                }
            }
        }
    }
    .ul2 {
        li {
            width: 1200px;
            border-bottom: 1px dashed #dbd4cd;
            padding-top: 10px;
            margin: 10px auto;
            padding-bottom: 10px;
            div {
                width: 70px;
                height: 65px;
                margin-left: 30px;
                float: left;
                text-align: center;
                background: #f7b71d;
                margin-right: 40px;
                padding: 0;
                margin-top: 0px;
                .p1 {
                    display: block;
                    text-align: center;
                    color: #fff;
                    font-size: 24px;
                    font-weight: bold;
                    margin-top: 5px;
                }
                .p2 {
                    display: block;
                    text-align: center;
                    color: #fff;
                    font-size: 13px;
                    margin-top: 5px;
                }
            }
        }
    }
    .p3 {
        a {
            display: inline-block;
            font-size: 22px;
            color: #000000;
            font-weight: normal;
            text-decoration: none;
            line-height: 140%;
            &:hover {
                color: #f7b71d;
            }
        }
    }
    .p4 {
        font-size: 12px;
        padding-top: 2px;
        color: #dbd4cd;
        font-weight: normal;
        text-decoration: none;
        line-height: 140%;
    }
}

.show_article {
    .cate {
        width: 360px;
        height: 40px;
        margin: 50px auto;
    }
    .ul1 {
        li {
            float: left;
            a {
                display: block;
                width: 120px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                color: #000;
                font-size: 14px;
                background-color: #f9f9f9;
                &:hover {
                    color: #fff;
                    background-color: rgb(247, 183, 29);
                }
            }
        }
    }
    h5 {
        font-size: 16px;
        color: #f7b71d;
        text-align: center;
        line-height: 34px;
    }
    .ps {
        text-align: center;
        line-height: 50px;
        color: #5a5a5a;
    }
    .des {
        color: #666;
        font-size: 12px;
        line-height: 24px;
        background-color: #f1f1f1;
        border: 1px solid #e5e5e5;
        text-indent: 2em;
        padding: 0 10px;
        margin-bottom: 30px;
    }
    .content {
        p {
            font-size: 14px;
            line-height: 28px;
            color: #5a5a5a;
            text-indent: 2em;
            padding: 0 10px;
        }
        span {
            display: inline-block;
            font-size: 12px;
            color: #5a5a5a;
            line-height: 24px;
            padding-left: 10px;
            a {
                font-size: 12px;
                color: #f7b71d;
                line-height: 24px;
                &:hover {
                    color: #5a5a5a;
                }
            }
        }
    }
    h4 {
        border-bottom: 1px solid #e2e2e2;
        font-size: 20px;
        line-height: 34px;
    }
}

.box_cgal {
    width: 100%;
    margin: 0 auto;
}

.cgal {
    ul {
        margin-top: 40px;
        li {
            float: left;
            width: 280px;
            margin-right: 22px;
            margin-bottom: 22px;
            padding: 15px;
            border: 1px solid rgb(238, 238, 238);
            &:hover {
                border-color: rgb(247, 183, 29);
            }
            .img {
                display: block;
                width: 250px;
                height: 200px;
            }
            p {
                font-size: 16px;
                color: #f7b71d;
                display: block;
                text-align: center;
                line-height: 140%;
                margin-bottom: 8px;
            }
        }
    }
}

.page {
    clear: both;
    font-size: 14px;
    margin: 20px auto;
    color: #5a5a5a;
    text-align: center;
    padding: 30px 0;
    .current {
        color: #f7b71d;
        border-color: #f7b71d;
    }
    span {
        display: inline-block;
        padding: 10px;
        border: 1px solid #5a5a5a;
        color: #5a5a5a;
        margin: 3px;
    }
    a {
        display: inline-block;
        padding: 10px;
        border: 1px solid #5a5a5a;
        color: #5a5a5a;
        margin: 3px;
        text-decoration: none;
        &:hover {
            color: #f7b71d;
            border-color: #f7b71d;
        }
    }
}

.box_zxyy {
    width: 100%;
    margin: 0 auto;
}

.zxyy_left {
    width: 655px;
    height: 290px;
    margin-top: 40px;
    margin-bottom: 60px;
    position: relative;
    img {
        width: 655px;
        height: 290px;
        z-index: 1;
    }
    h2 {
        font-size: 33px;
        color: rgb(247, 183, 29);
        line-height: 140%;
        text-align: center;
        z-index: 3;
        position: absolute;
        left: 230px;
        top: 70px;
    }
    p {
        width: 300px;
        color: rgb(255, 255, 255);
        font-size: 15px;
        line-height: 170%;
        text-align: center;
        z-index: 3;
        position: absolute;
        left: 150px;
        top: 150px;
    }
    a {
        display: block;
        width: 190px;
        height: 70px;
        font-size: 15px;
        color: #fff;
        background: rgb(247, 183, 29);
        line-height: 70px;
        text-align: center;
        margin-left: 35px;
        text-indent: 0px;
        -webkit-border-radius: 3px;
        border-radius: 3px;
        transition: all 2s;
        position: absolute;
        bottom: -30px;
        right: -35px;
        &:hover {
            background-color: #e19f00;
        }
    }
}

.zxyy_right {
    width: 420px;
    margin-top: 40px;
    margin-bottom: 60px;
    position: relative;
    p {
        line-height: 40px;
        margin-bottom: 20px;
        span {
            font-size: 14px;
            color: #5a5a5a;
        }
    }
    input {
        width: 343px;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        color: #000;
        text-indent: 5px;
        background-color: #f7f7f7;
        border: 1px solid #dedede;
        float: right;
        border-radius: 3px;
    }
    a {
        display: block;
        width: 343px;
        height: 40px;
        font-size: 15px;
        color: #fff;
        background: rgb(247, 183, 29);
        line-height: 40px;
        text-align: center;
        margin-left: 35px;
        text-indent: 0px;
        -webkit-border-radius: 3px;
        border-radius: 3px;
        transition: background-color 0.8s ease 0s;
        position: absolute;
        bottom: -60px;
        left: 40px;
        &:hover {
            background-color: #e19f00;
        }
    }
}

.lxwm_left {
    p {
        font-size: 20px;
        line-height: 46.2px;
        color: #f7b71d;
        width: 470px;
        border-bottom: 1px dashed rgb(217, 217, 217);
        padding: 20px 0 20px 10px;
    }
}

#demo {
    background: #FFF;
    overflow: hidden;
    img {
        width: 200px;
        height: 170px;
        margin-right: 25px;
    }
}

#indemo {
    float: left;
    width: 800%;
    height: 195px;
}

#demo1 {
    float: left;
}

#demo1 {
    ul {
        li {
            float: left;
        }
    }
    p {
        font-size: 14px;
        color: #000;
        line-height: 140%;
        text-align: center;
    }
}

#demo2 {
    float: left;
}

#demo3 {
    float: left;
    ul {
        li {
            float: left;
        }
    }
    p {
        font-size: 14px;
        color: #000;
        line-height: 140%;
        text-align: center;
    }
}

#demo4 {
    float: left;
}

#demo5 {
    background-color: rgb(249, 249, 249);
    overflow: hidden;
    img {
        width: 200px;
        height: 130px;
        margin-right: 25px;
    }
}

.flexslider {
    width: 100%;
    height: 100%;
    .slides {
        li {
            margin: 0 auto;
            position: relative;
            width: 100%;
            height: 500px;
            overflow: hidden;
            zoom: 1;
        }
        a {
            img {
                width: 100%;
                height: 500px;
                display: block;
            }
        }
    }
}

.flex-direction-nav {
    a {
        width: 70px;
        height: 70px;
        line-height: 99em;
        overflow: hidden;
        margin: -35px 0 0;
        display: block;
        background: url(images/ad_ctr.png) no-repeat;
        position: absolute;
        top: 50%;
        z-index: 1;
        cursor: pointer;
        opacity: 0;
        filter: alpha(opacity=0);
        -webkit-transition: all .3s ease;
        border-radius: 35px;
    }
    .flex-next {
        background-position: 0 -70px;
        right: 0;
    }
    .flex-prev {
        left: 0;
    }
    .flexslider:hover .flex-next:hover,
    .flexslider:hover .flex-prev:hover {
        opacity: 1;
        filter: alpha(opacity=50)
    }
}

.flex-control-nav {
    width: 100%;
    position: absolute;
    bottom: 10px;
    text-align: center;
    li {
        margin: 0 2px;
        display: inline-block;
        zoom: 1;
        *display: inline;
    }
}

.flex-control-paging {
    li {
        a {
            background: url(../img/dot.png) no-repeat 0 -16px;
            display: block;
            height: 16px;
            overflow: hidden;
            text-indent: -99em;
            width: 16px;
            cursor: pointer;
        }
    }
    li.active a,
    li a.flex-active {
        background-position: 0 0;
    }
}

.dowebok1 {
    width: 1200px;
    margin: 50px auto 30px;
}

.dowebok1 .case_title {
    display: block;
    width: 240px;
    height: 24px;
    line-height: 24px;
    color: #000;
    font-size: 14px;
    text-align: center;
}

.dowebok2 {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 50px;
}

.dowebok1 li {
    margin-left: 10px;
}

.dowebok2 li {
    margin-left: 10px;
}

.dowebok1 img {
    vertical-align: top;
    width: 240px;
    height: 170px;
}

.dowebok2 img {
    vertical-align: top;
    width: 240px;
    height: 130px;
}

.str_wrap {
    overflow: hidden;
    font-size: 12px;
    line-height: 16px;
    position: relative;
    white-space: nowrap
}

.str_move {
    white-space: nowrap;
    position: absolute;
    top: 0;
    left: 0;
    cursor: move
}

.str_move_clone {
    display: inline-block;
    vertical-align: top;
    position: absolute;
    left: 100%;
    top: 0
}

.str_vertical .str_move_clone {
    left: 0;
    top: 100%
}

.str_down .str_move_clone {
    left: 0;
    bottom: 100%
}

.str_down .str_move,
.str_vertical .str_move {
    white-space: normal;
    width: 100%
}

.no_drag .str_move,
.noStop .str_move,
.str_static .str_move {
    cursor: inherit
}

.str_wrap img {
    max-width: none
}


/****************************** media ******************************/

// @import "./components/media";
//
